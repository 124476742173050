<template>
  <v-dialog
    v-model="dialogEditAtividadeGestor"
    @click:outside="$emit('update:dialogEditAtividadeGestor', false)"
    @keydown.esc="$emit('update:dialogEditAtividadeGestor', false)"
    scrollable
    fullscreen
  >
    <v-card tile :loading="loading">
      <v-card-title class=" align-center accent text-h5 font-weight-bold pa-3">
        Editar Atividade
        <v-spacer></v-spacer>

        <v-chip
          class="mr-2 text-center"
          :color="corStatus"
          small
          dark
          depressed
          v-if="!loading"
          label
        >
          {{ atividade.status | statusAtividade }}
        </v-chip>

        <v-btn icon @click="$emit('update:dialogEditAtividadeGestor', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text
        style="height: calc(100vh - 69px); overflow:hidden;"
        class="pa-0"
      >
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <v-tabs v-model="tab">
              <v-tab>informações</v-tab>
              <v-tab>Anexos</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-divider></v-divider>
                <v-card-text
                  class=""
                  style="height: calc(100vh - 179px); overflow:auto;"
                >
                  <v-form ref="form" v-model="validForm" lazy-validation>
                    <v-row>
                      <v-col class="py-0 pt-3" cols="12">
                        <v-text-field
                          v-model="atividade.titulo"
                          label="Título"
                          :disabled="
                            atividade.status === -1 || atividade.status === 7
                          "
                          required
                          :rules="formRules"
                          counter="255"
                          max="255"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        class="py-0"
                        v-if="!clienteComponent"
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="atividade.cliente_nome_fantasia"
                          label="Cliente"
                          class="mt-9"
                          readonly
                          :disabled="
                            atividade.status === -1 || atividade.status === 7
                          "
                          dense
                          append-icon="mdi-magnify"
                        ></v-text-field>
                      </v-col>

                      <!-- data inicio-->
                      <v-col
                        class="mt-8 py-0"
                        cols="12"
                        :md="clienteComponent ? '6' : '3'"
                      >
                        <DataField
                          :disabled="
                            atividade.status === -1 || atividade.status === 7
                          "
                          label="Data Início"
                          :data_sync.sync="atividade.inicio"
                          obrigatorio
                          :data_min="today"
                          :data_max="atividade.entrega"
                        />
                      </v-col>

                      <!--data entrega -->
                      <v-col
                        class="mt-8 py-0"
                        cols="12"
                        :md="clienteComponent ? '6' : '3'"
                      >
                        <DataField
                          :disabled="
                            atividade.status === -1 || atividade.status === 7
                          "
                          label="Data Entrega"
                          :data_sync.sync="atividade.entrega"
                          obrigatorio
                          :data_min="
                            atividade.inicio ? atividade.inicio : today
                          "
                        />
                      </v-col>

                      <!--Servicos prestados -->
                      <v-col class="py-0" cols="12" md="6">
                        <v-autocomplete
                          class="mt-9"
                          v-model="selectedServico"
                          :items="cliente_servicos"
                          dense
                          :loading="loadingServicos"
                          :disabled="
                            atividade.status === -1 || atividade.status === 7
                          "
                          hide-no-data
                          item-text="servico"
                          item-value="servico_id"
                          label="Serviços"
                          append-icon="mdi-magnify"
                          return-object
                          required
                          :rules="formRules"
                        ></v-autocomplete>
                      </v-col>

                      <v-col class="py-0" cols="12" md="6">
                        <v-select
                          v-model="selectedPrioridade"
                          :items="prioridades"
                          :disabled="
                            atividade.status === -1 || atividade.status === 7
                          "
                          label="Prioridade"
                          item-text="descricao"
                          item-value="id"
                          return-object
                          dense
                          class="pt-8"
                        >
                          <template v-slot:selection="{ attrs, item }">
                            <v-icon :color="item.cor">
                              mdi-flag
                            </v-icon>
                            {{ item.descricao }}
                          </template>
                        </v-select>
                      </v-col>

                      <!--Explicao do servico -->
                      <v-col class="py-0" cols="12" md="6">
                        <label>Descrição dos serviços</label>
                        <v-textarea
                          :disabled="
                            atividade.status === -1 || atividade.status === 7
                          "
                          v-model="atividade.descricao_servicos"
                          placeholder="Insira aqui a descrição do serviço..."
                          auto-grow
                          :rows="4"
                          required
                          :rules="formRules"
                        ></v-textarea>
                      </v-col>

                      <v-col
                        v-if="$vuetify.breakpoint.mdAndUp"
                        cols="12"
                        md="auto"
                        class="d-flex align-center justify-center pa-1  "
                      >
                        <v-divider vertical></v-divider>
                      </v-col>

                      <v-col class="py-0" cols="12" md="">
                        <label>Informações pertinentes</label>
                        <v-textarea
                          style="width:100%"
                          :disabled="
                            atividade.status === -1 || atividade.status === 7
                          "
                          v-model="atividade.pertinentes"
                          placeholder="Insira aqui as informações pertinentes..."
                          auto-grow
                          :rows="4"
                          required
                          :rules="formRules"
                        ></v-textarea>
                      </v-col>
                    </v-row> </v-form
                ></v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-divider></v-divider>
                <v-col cols="12">
                  <InternFileManager
                    :disabled="
                      atividade.status === -1 || atividade.status === 7
                    "
                    origem="atividades"
                    :origem_id="this.atividade.id"
                    title="Anexos"
                    :smallComponent="true"
                  />
                </v-col>
              </v-tab-item>
            </v-tabs-items>

            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="4">
            <div
              class="pa-0"
              style="border-left: 1px solid #e0e0e0 !important;"
            >
              <v-btn-toggle
                class="py-1 mt-1"
                color="deep-purple accent-3"
                v-model="text"
                group
              >
                <v-btn value="all" small @click="selectedTipoComentario('all')">
                  <span class="hidden-sm-and-down">Todos</span>
                </v-btn>
                <v-btn
                  value="Histórico"
                  small
                  @click="selectedTipoComentario('Histórico')"
                >
                  <span class="hidden-sm-and-down">Histórico</span>
                </v-btn>
                <v-btn
                  value="Comentário"
                  small
                  @click="selectedTipoComentario('Comentário')"
                  ><span class="hidden-sm-and-down">Comentário</span>
                </v-btn>
              </v-btn-toggle>
            </div>

            <v-divider></v-divider>
            <v-card-text
              ref="container"
              style=" border-left: 1px solid #e0e0e0 !important; height: calc(100vh - 265px); overflow:auto; background-color: #F5F5F5 "
            >
              <AtividadesComentarios
                v-if="atividade && atividade.id"
                :atividade_id="atividade.id"
                :comentario_add="comentarioAux"
                :comentario_tipo="comentarioTipo"
                :selected_tipo="selectedTipo"
                :key="comentarioKey"
                @last-scroll="scrollToEnd"
              />
            </v-card-text>
            <v-divider></v-divider>
            <v-card tile flat color="accent" class="px-3 py-2">
              <v-textarea
                name="comentario-gestor"
                label="Escreva aqui seu comentário"
                hint="comentário"
                no-resize
                rows="1"
                v-model="comentario"
                append-outer-icon="mdi-comment"
                @keydown.enter="addComentario()"
                @click:append-outer="addComentario()"
              >
              </v-textarea>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn
          class="mr-3"
          @click="$emit('update:dialogEditAtividadeGestor', false)"
        >
          voltar
        </v-btn>
        <v-divider
          v-if="
            atividade.status == -1 ||
              atividade.status == 3 ||
              (atividade.status >= 0 && atividade.status <= 1)
          "
          vertical
          class="mx-4 "
        ></v-divider>
        <div>
          <v-btn
            color="error"
            class="white--text mr-3  ml-2"
            v-if="atividade.status >= 0 && atividade.status <= 1"
            @click="cancelarAtividadeGestor"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            class="white--text mr-3  ml-2"
            v-if="atividade.status === -1"
            @click="ativarAtividadeGestor"
          >
            Ativar
          </v-btn>
          <v-btn
            color="error"
            class="white--text mr-3  ml-2"
            v-if="atividade.status === 3"
            @click="refazerAtividadeGestor"
          >
            Refazer
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            color="success"
            class="white--text mr-3  ml-2"
            v-if="atividade.status === 3"
            @click="aprovarAtividadeGestor"
          >
            Aprovar
          </v-btn>
        </div>
        <v-divider
          v-if="atividade.status === 3"
          vertical
          class="mx-3"
        ></v-divider>
        <v-btn
          color="secondary"
          class="ml-3"
          @click="updateAtividadeCliente"
          :disabled="
            atividade.status === -1 || atividade.status === 7 || !validForm
          "
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { putAtividadeCliente } from "@/api/gestor/atividades_clientes.js";
// import { fetchClienteServicos } from "@/api/gestor/cliente_servicos.js";
import { format } from "date-fns";
import { mapState } from "vuex";
import { fetchAtividade } from "@/api/geral/atividades_clientes.js";

export default {
  name: "AtividadesGestor",

  props: {
    dialogEditAtividadeGestor: {
      type: Boolean,
      required: true,
    },

    atividadeSelected: {
      type: [String, Number],
      default: null,
    },

    clienteComponent: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: [Number, String],
    },
  },

  components: {
    DataField: () => import("../../../components/fields/DataField.vue"),

    InternFileManager: () =>
      import("../../../components/midias/InternFileManager.vue"),

    AtividadesComentarios: () =>
      import("@/components/comentarios/AtividadesComentarios.vue"),
  },

  data() {
    return {
      selectedTipo: "",
      comentarioKey: 0,
      selectedCliente: null,
      tab: 0,
      text: "all",
      selectedServico: null,
      selectedPrioridade: null,
      loading: false,
      clientes: [],
      cliente_servicos: [],
      atividade: {},
      comentarioAux: "",
      comentarioTipo: 0,
      comentario: null,
      searchClientes: "",
      validForm: true,
      today: format(new Date(), "yyyy-MM-dd"),
      desabilitError: false,
      formRules: [inputRequired],
      desabilitadoServico: true,
      nenhumServicoErro: false,
      loadingServicos: false,
      prioridades: [
        { id: 1, descricao: "Baixa", cor: "prioridade_baixa" },
        { id: 2, descricao: "Normal", cor: "prioridade_normal" },
        { id: 3, descricao: "Urgente", cor: "prioridade_urgente" },
        { id: 4, descricao: "Emergência", cor: "prioridade_emergencia" },
      ],
    };
  },

  watch: {
    selectedServico() {
      if (this.selectedServico) {
        this.atividade.servico_id = this.selectedServico.servico_id;
      }
    },
    selectedPrioridade() {
      if (this.selectedPrioridade) {
        this.atividade.prioridade = this.selectedPrioridade.id;
      }
    },
  },
  computed: {
    ...mapState("Usuario", {
      usuarioName: (state) => state.usuario.name,
    }),
    corStatus() {
      let result = "";
      switch (this.atividade.status) {
        case -1:
          result = "red";
          break;
        case 0:
          result = "grey";
          break;
        case 1:
          result = "blue-grey";
          break;
        case 2:
          result = "yellow darken-4";
          break;
        case 3:
          result = "green";
          break;
        case 4:
          result = "purple";
          break;
        case 5:
          result = "green darken-4";
          break;
        case 6:
          result = "deep-purple accent-4";
          break;
        case 7:
          result = "light-green";
          break;
      }
      return result;
    },
  },

  methods: {
    selectedTipoComentario(tipo) {
      this.comentarioTipo = null;
      this.comentarioAux = null;
      this.comentario = null;
      this.selectedTipo = tipo;
      this.comentarioKey += 1;
    },
    scrollToEnd() {
      let container = this.$refs.container;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    },
    addComentario() {
      this.comentarioAux = this.comentario;
      this.comentarioTipo = 1;
      this.comentarioKey += 1;
      this.comentario = null;
    },

    aprovarAtividadeGestor() {
      let atividade = {};
      atividade.status = 5;
      this.$toast.success("Atividade aprovada!");
      atividade.cliente_id = this.atividade.cliente_id;
      return putAtividadeCliente(this.atividade.id, atividade).then(
        (response) => {
          if (response.status === 201) {
            this.getAtividade();
            this.$emit("fetch-notificacoes");
            this.comentarioAux = `${this.usuarioName}, mudou status para APROVADO`;
            this.comentarioKey += 1;
            this.comentarioTipo = 0;
          }
        }
      );
    },

    cancelarAtividadeGestor() {
      let atividade = {};
      atividade.status = -1;
      this.$toast.success("Atividade cancelada!");
      atividade.cliente_id = this.atividade.cliente_id;
      return putAtividadeCliente(this.atividade.id, atividade).then(
        (response) => {
          if (response.status === 201) {
            this.getAtividade();
            this.$emit("fetch-notificacoes");
            this.comentarioAux = `${this.usuarioName}, mudou status para CANCELADO`;
            this.comentarioKey += 1;
            this.comentarioTipo = 0;
          }
        }
      );
    },

    ativarAtividadeGestor() {
      let atividade = {};
      atividade.status = 0;
      this.$toast.success("Atividade ativa!");
      atividade.cliente_id = this.atividade.cliente_id;
      return putAtividadeCliente(this.atividade.id, atividade).then(
        (response) => {
          if (response.status === 201) {
            this.getAtividade();
            this.$emit("fetch-notificacoes");
            this.comentarioAux = `${this.usuarioName}, mudou status para ATIVO`;
            this.comentarioKey += 1;
            this.comentarioTipo = 0;
          }
        }
      );
    },

    refazerAtividadeGestor() {
      let atividade = {};
      atividade.status = 4;
      this.$toast.info("Enviado para refazer!");
      atividade.cliente_id = this.atividade.cliente_id;
      return putAtividadeCliente(this.atividade.id, atividade).then(
        (response) => {
          if (response.status === 201) {
            this.getAtividade();
            this.$emit("fetch-notificacoes");
            this.comentarioAux = `${this.usuarioName}, mudou status para REFAZER `;
            this.comentarioKey += 1;
            this.comentarioTipo = 0;
          }
        }
      );
    },
    /*puxa todos os clientes*/

    /*Remove o clips do anexo */
    remove(item) {
      this.atividade.files.splice(
        this.atividade.files.findIndex((file) => file.name === item),
        1
      );
    },

    updateAtividadeCliente() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let atividade = {};
        atividade.cliente_id = this.atividade.cliente_id;
        atividade.servico_id = this.atividade.servico_id;
        atividade.status = this.atividade.status;
        atividade.inicio = this.atividade.inicio;
        atividade.entrega = this.atividade.entrega;
        atividade.descricao_servicos = this.atividade.descricao_servicos;
        atividade.pertinentes = this.atividade.pertinentes;
        atividade.prioridade = this.atividade.prioridade;
        atividade.titulo = this.atividade.titulo;

        putAtividadeCliente(this.atividade.id, atividade)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Atividade atualizado com sucesso!");
              this.$emit("update:dialogEditAtividadeGestor", false);
              this.$emit("fetch-notificacoes");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    getAtividade() {
      this.loading = true;
      return fetchAtividade(this.atividadeSelected)
        .then((response) => {
          this.atividade = response;
          this.cliente_servicos = [...this.atividade.servicos];

          this.selectedServico = this.cliente_servicos.find(
            (item) => item.servico_id === this.atividade.servico_id
          );
          this.selectedPrioridade = this.prioridades.find(
            (item) => item.id === this.atividade.prioridade
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.loading = true;
    await this.getAtividade();
    this.loading = false;
  },
};
</script>

<style lang="scss"></style>
